<template>
  <div class="row">
      <div class="col-md-12">
        <div id="appl">
            <div id="chart" ref="chart">
                <apexcharts type="donut" :width="500" :height="380" :options="chartOptions" :series="chartOptions.series"></apexcharts>
            </div>
        </div>
        <div class="bottom_description">
            <div class="">
                <p class="total_credit_used">
                    total credit used
                </p>
                <p class="__number">
                    <strong>14,559</strong>
                </p>
            </div>
            <div class="">
                <p class="__total__contacts">
                    total contacts
                </p>
                <p class="__number">
                   <strong> 14,559</strong>
                </p>
            </div>
        </div>
        <div class="__view__more d-flex justify-content-center align-items-center pb-4">
            <button>View more</button>
        </div>
      </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
      apexcharts: VueApexCharts,
    },
    data: function() {
      return {
        // series1: [2, 4, 5, 6],
        // labels1: ['a', 'b', 'c', 'd'],
      }
    },
    methods: {
        seriesPercentage(data) {
            let total = 0;
            for(let i = 0; i <= this.chartOptions.series.length; i++) {
                total += this.chartOptions.series[i]
            }

            let percentage = data / total * 100;
            return percentage;
        }
    },
    computed: {
      chartOptions: function() {
        return {
            colors: ['#36D962', '#30A1F0', '#F0E930', '#F7921C'],
            legend: {
                fontSize: '14px',
                // fontFamily: 'Helvetica, Arial', 
                //TODOD: FIND font-family
                fontWeight: 400,
                position: "bottom",
                floating:false,
                itemMargin: {
                },
                formatter: function(seriesName, opts) {
                    let total = 0;
                    let series = opts.w.globals.series
                    let singleSeries = opts.w.globals.series[opts.seriesIndex]
                    for(let i = 0; i < series.length; i++ ) {
                        total += series[i]
                    }
                    let perCentage = parseInt(( singleSeries / total * 100 ))
                    return '<div class="legend-info" style="display:inline-block; margin-bottom:1.5em!important;">' + '<strong>' + singleSeries + '</strong>' + '<span>' + seriesName + '</span>' + '<span>' + perCentage + '%' + '</span>' + '</div>'
                }
            },
            dataLabels: {
                enabled: false,
            },
            labels: ['Delivered', 'Submitted', 'Expired', 'Undelivered'],
            plotOptions: {
                pie: {
                    donut: {
                    size: '90%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            color: "#575761",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "29px",
                            lineHeight: "32px",
                            formatter: function (val) {
                                return val
                            }
                        },
                        total: {
                            show: true,
                            label: 'Total Messages',
                            color: '#575761',
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "13px",
                            textTransform:'capitalize',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                return  a + b
                                }, 0)
                            }
                        }
                    }
                    }
                }
            },
            series: [400, 100, 300, 500]
            }
        },
    },
}
</script>

<style scoped>
.bottom_description{
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-top: 1px solid #F3F3F4;
    padding-top: 2em;
    padding-bottom: 4em;
    padding-left: 20%;
    padding-right: 20%;
}
.total_credit_used,.__total__contacts{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484A4F;
}
button{
    outline:none;
    border:none;
    background: #F5F5F5;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9E9EA7;
    height: 40px;
    width:142.64px;
}
</style>